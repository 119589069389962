import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import LoanFlowLogo from '../img/loanflow/loanflow_logo.svg'

import { colors, presets } from '../utils/theme'
import { rhythm } from '../utils/typography'
import Button from '../components/Button'
const logos = {
  'loanflow': LoanFlowLogo,
}

const styles = {
  container: {
    margin: `${rhythm(7)} auto`,
    padding: rhythm(1.5),
    border: `2px solid ${colors.mediumGreen}`,
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [presets.Mobile]: {
      maxWidth: '80%'
    },
    [presets.Desktop]: {
      maxWidth: '500px',
    },
  },
  logo: {
    width: '200px',
    marginBottom: rhythm(1.5),
  },
  title: {
    margin: 0,
    color: colors.green,
  },
  spacer: {
    width: '100%',
    display: 'flex',
    flex: 1,
    borderBottom: `2px solid ${colors.blueGreen}`,
    margin: `${rhythm(1)} auto`
  },
  text: {
    color: colors.darkGreen,
    marginBottom: rhythm(0.9)
  }
}

export const PaymentConfirmed = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter
  const {
    showHeaderLinks,
    productLogoKey,
    title,
    successText,
    calendarTitle,
    calendarText,
    calendarUrl,
  } = frontmatter

  const now = new Date(Date.now())
  const calendarYear = now.getUTCFullYear().toString()
  const month = (now.getUTCMonth() + 1).toString()
  const calendarMonth = month < 10 ? `0${month}` : month
  const calendarFullUrl = `${calendarUrl}${calendarYear}-${calendarMonth}`
  
  return (
    <Layout showLinks={showHeaderLinks}>
      <div css={styles.container}>
        <img src={logos[productLogoKey]} css={styles.logo} alt='LoanSnap'/>
        <h2 style={styles.title}>{title}</h2>
        <div style={styles.spacer}></div>
        <div style={styles.text}>{successText}</div>
        <div style={styles.text}>{calendarText}</div>
        <div>
          <Button
            href={calendarFullUrl}
            analyticsTargetName='Meet Us - Calendar'
            text={calendarTitle}
            style={{margin: 'auto'}}
          />
        </div>
      </div>
    </Layout>
  )
}

PaymentConfirmed.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        showHeaderLinks: PropTypes.bool,
        productLogoKey: PropTypes.string,
        title: PropTypes.string,
        successText: PropTypes.string,
        calendarTitle: PropTypes.string,
        calendarText: PropTypes.string,
        calendarUrl: PropTypes.string,
      })
    })
  })
}

export default PaymentConfirmed

export const pageQuery = graphql`
  query PaymentConfirmed {
    markdownRemark(frontmatter: {templateKey: {eq: "payment-confirmed"} }) {
      frontmatter {
        showHeaderLinks
        productLogoKey
        title
        successText
        calendarTitle
        calendarText
        calendarUrl
      }
    }
  }
`